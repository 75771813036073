

.special_discount_dialog {
    max-width: 688px;
    width: 100%;
    padding: 24px;
    gap: 24px;
    border-radius: 17px;
    background: #FFFFFF;
    border: none;
}