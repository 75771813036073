@use "../../../../scss/abstracts" as *;
@use "../../../../scss/layout/typography" as *;
@use "../../../../scss/layout/spacing" as *;
@use "../../../../scss/theme/borders" as theme;

.buying-this-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    padding-bottom: 16px;

    @include breakpoint-max(md) {
        padding-bottom: 0px;
        &::after {
            content: "";
            height: 1px;
            background-color: theme.$border-color;
        }
    }
    .buying-this {
        width: 100%;
        margin-top: 0 !important;
        .card-body {
            flex-wrap: wrap;
            font-family: $font-body;

            @include breakpoint-max(md) {
                padding-bottom: 0px;
            }

            .col-divider {
                gap: rem(16);

                @include breakpoint-min(md) {
                    gap: rem(24);
                }
            }
            .vjx-img-wrap {
                background-color: #f4f5f6;
                border-radius: 8px;
                img {
                    width: 123px;
                    height: 92px;
                    border-radius: theme.$border-radius-sm;
                    object-fit: cover;
                }
                @include breakpoint-max(sm) {
                    img {
                        width: 112px;
                        height: 84px;
                    }
                }
            }
        }

        .ticket-info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            flex: 1;
            .ticket-loc {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;
                font-family: Poppins;
                font-size: $body-2;
                font-weight: $fw-semi-bold;
                line-height: 24px;
                margin-bottom: 0;

                @include breakpoint-max(md) {
                    font-size: $small;
                    font-weight: $fw-medium;
                }
            }
            .right-side {
                display: flex;
                flex-direction: row;
                gap: 24px;
                .ticket-count-info {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    font-family: $font-body;
                    .ticket-count {
                        @extend .fs-md;
                        @extend .fs-md-bold;
                        line-height: 32px;
                    }
                    .ticket-text {
                        font-weight: $fw-normal;
                        font-size: $body-2;
                        line-height: 24px;
                    }
                }
                .form-select {
                    max-width: 180px;
                }
            }
        }
    }
}
