@use '../../scss/abstracts' as *;

.ticket-card {
	box-shadow: $box-shadow;

	.event-image-lg {
		width: 217px;
		height: 217px;
	}

	.details {
		.card-title {
			font-size: $body-2;
			margin-bottom: rem(3);
		}

		.event-details {
			font-size: $caption;
			margin-bottom: rem(8);
		}
	}
}
