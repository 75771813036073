.special-discount-modal {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .special_discount_title {
      h4 {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: rgba(20, 20, 22, 1);
      }
      p {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 23.8px;
        color: rgba(20, 20, 22, 1);
      }
    }

    .vjx-select-bxo {
        label {
            font-size: 14px;
            font-weight: 400;
            line-height: 23.8px;
            text-align: left;
            color: rgba(0, 0, 0, 1);
            margin-bottom: 8px;
        }
    }
  
    .ticket-selection {

      h6 {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
      }
    }
  
    .tickets-list {
      background: rgba(244, 245, 246, 1);
      display: flex;
      flex-direction: column;
      gap: 16px;
      border-radius: 16px;
      padding: 18px;
      p {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        color: rgba(119, 126, 144, 1);
      }
    }
  
    .ticket-item {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 12px;
        gap: 16px;
        border-radius: 16px;
        background: rgba(255, 255, 255, 1);
        border: 2px solid rgba(230, 232, 236, 1);
        cursor: pointer;

      .vjx-img {
        width: 80px;
        height: 72px;
        object-fit: cover;
        border-radius: 8px;
        background: rgb(194, 194, 194);
      }
      &.selected {
        border: 2px solid rgba(62, 139, 247, 1)
      }
    }
  
    .important-info {
        display: grid;
        grid-template-columns: 24px 1fr;
        background-color: rgba(62, 169, 247, 0.05);
        border-radius: 8px;
        padding: 12px;
        gap: 8px;
        svg {
            width: 24px;
            height: 24px;
        }
  
      h6 {
        color: #3E8BF7;
        margin-bottom: 2px;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
      }
  
      p {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 23.8px;
        text-align: left;
        color: #3E8BF7;
        margin-bottom: 0;
      }
    }
  
    .modal-footer {
      .btn-primary {
        background-color: #007bff;
        border-color: #007bff;
  
        &:hover {
          background-color: #0056b3;
          border-color: #0056b3;
        }
      }
    }
  }