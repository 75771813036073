@use '../../../../scss/abstracts' as *;
@use '../../../../scss/layout/typography' as *;
@use '../../../../scss/layout/spacing' as *;
@use '../../../../scss/theme' as *;

.get_presale {
	.presale_desc {
		padding-top: rem(24);
		h5 {
			font-weight: $fw-bold;
			font-size: $body-2;
			line-height: 16px;
			color: #000000;
		}
		p {
			font-weight: $fw-normal;
			font-size: $small;
			line-height: 24px;
			color: $clr-neutral-3;
			@include multi-line-truncate(6);
		}
	}
}
.get_presale_desktop {
	.card-body {
		width: 100%;
	}
	.soldout_icon {
		padding-bottom: rem(16);
		width: 80px;
	}
	.presale_heading {
		font-weight: $fw-semi-bold;
		font-size: $body-1;
		line-height: 32px;
		color: black;
		margin-bottom: rem(4);
	}
	.presale_info {
		font-weight: $fw-normal;
		font-size: $body-2;
		line-height: 24px;
		color: black;
	}
	.get_presale_inner {
		margin-top: $text-spacer-xs;
		border-radius: $border-radius;
		border: border(); // neutral 6
		background-color: #ffffff;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: rem(8) rem(12);
		gap: rem(16);
		.presale_input {
			flex: 1;
			height: 100%;
			border: none;
			outline: none;
			background-color: transparent;
			font-weight: $fw-normal;
			font-size: $body-2;
			line-height: 24px;
			// color: #353945; // neutral 3 // color has not been specified in design
		}
		.presale_input::placeholder {
			color: $input-placeholder-color; // neutral 4
		}
		.presale_input::-ms-input-placeholder {
			color: $input-placeholder-color; // neutral 4
		}
	}

	.get_presale_inner:focus-within {
		border-color: $input-focus-border-color; // this has not been specified in design
	}

	.incorrect {
		border-color: $danger; // Primary/3
		color: $danger;
	}

	.presale_incorrect {
		margin-top: $text-spacer-xs;
	}
}

.get_presale_mobile {
	flex-direction: column;
	.mob_layout {
		width: 100%;
		display: flex;
		flex-direction: column;
		.presale_heading {
			font-weight: $fw-semi-bold;
			font-size: $body-2;
			line-height: 24px;
			color: black;
			margin-bottom: rem(4);
		}
		.presale_info {
			font-weight: $fw-normal;
			font-size: $body-2;
			line-height: 24px;
			color: black;
		}
		.presale_input {
			margin-top: rem(8);
			padding: rem(16) rem(18);
			border-radius: $border-radius;
			border: border(); // neutral 6
			background-color: $white;
			flex: 1;
			height: 100%;
			outline: none;
			font-weight: $fw-normal;
			font-size: $body-2;
			line-height: 24px;
			margin-bottom: rem(8);
		}
		.presale_input::placeholder {
			color: $input-placeholder-color; // neutral 4
		}
		.presale_input::-ms-input-placeholder {
			color: $input-placeholder-color; // neutral 4
		}
		.presale_input:focus-within {
			border-color: $input-focus-border-color; // this has not been specified in design
		}

		.incorrect {
			border-color: $danger; // Primary/3
			color: $danger;
		}
	}
	.soldout_icon {
		padding-bottom: rem(16);
		width: 70px;
	}
}

.get_mobile_normal {
	background-color: rgba(255, 255, 255, 0.54); // Neutrals 8
	// border: 2px solid white; // Neutrals 6
	border: border(2px, white); // Neutrals 6
	border-radius: 20px;
	padding: rem(24);
	display: flex;
	@include breakpoint-min(sm) {
		display: none;
	}
}

.get_mobile_fixed {
	display: flex;
	background-color: $white; // Neutrals 8
	border: border();
	padding: rem(16) rem(24);
	border-top-right-radius: $border-radius;
	border-top-left-radius: $border-radius;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10;
	box-shadow: 0px 4px 64px 0px #0000001C;
	@include breakpoint-min(sm) {
		display: none;
	}
}

.get_desktop {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	border: 2px solid white;
	@include breakpoint-max(sm) {
		display: none !important;
	}
}
