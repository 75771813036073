@use '../../../../scss/abstracts' as *;
@use '../../../../scss/theme/colors' as *;
@use '../../../../scss/layout/spacing' as *;
@use '../../../../scss/layout/typography' as *;
@use '../../../../scss/layout/container' as *;

.purchaseFlow-event {
	position: relative;
	@include breakpoint-min(md) {
		padding-top: 0;
	}

	.event-container {
		&.card {
			.card-title {
				line-height: 24px;
				margin-bottom: rem(4);
			}
		}
		@include breakpoint-max(md) {
			&.card {
				border: none;
				background-color: transparent;

				.card-body {
					padding: 0px;

					.card-title {
						font-size: $small;
						font-weight: $fw-medium;
					}
				}
			}
		}
	}

	.event-image {
		&-wrapper {
			flex-shrink: 0;
		}

		& {
			border-radius: 16px;
		}
	}

	.details {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		font-weight: $fw-normal;
		flex-grow: 1;
		min-width: 0;
		color: #000000;
		font-size: $body-2;

		.card-title-sm {
			margin-bottom: 0 !important;
		}

		.spant {
			font-size: 12px;
			font-weight: 400;
			line-height: 20px;
			color: #000000;

		}

		.infob {
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			display: flex;	
			flex-direction: row;
			align-items: center;
			color: #353945;
			gap: 8px;
			margin-top: 0px;
		}

		.venue-info {
			color: #3e8bf7; // Secondary 1
		}

		.hstack {
			font-weight: 400;
			font-size: $caption;
		}
		@include breakpoint-min(md) {
			flex-direction: column;
			.hstack {
				font-size: $body-2;
			}
			.card-title-sm {
				margin-bottom: 4px !important;
			}
			.spant {
				font-size: 16px;
				line-height: 24px;
			}
			.infob {
				margin-top: 8px;
			}
		}
	}

	#event-info {
		margin-left: auto;
		margin-right: 2px;
		@include breakpoint-min(md) {
			border-color: $light;
			color: $muted;

			svg {
				width: 16px;
			}
		}

		@include breakpoint-max(md) {
			padding: 0px;
		}
	}
}
