@use '../../../../scss/abstracts' as *;
@use '../../../../scss/layout/typography' as *;
@use '../../../../scss/layout/spacing' as *;

.main_description {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;

	.desc_badge {
		line-height: 20px;
		margin-bottom: $text-spacer-xs;
	}

	.display-1 {
		color: black;
		@extend .fs-md;
		@extend .fs-md-bold;
		line-height: 32px;
		font-family: $font-heading-alt;
	}
	.event-venue {
		line-height: $body-1;
		margin-bottom: rem(8);
	}

	@include breakpoint-min(md) {
		.display-1 {
			font-size: rem(40);
			line-height: 48px;
		}
		.event-venue {
			line-height: 32px;
		}
		.card-text {
			font-size: $body-1;
			line-height: 32px;
		}
	}
	@include breakpoint-min(xl) {
		.display-1 {
			font-size: rem(56);
			line-height: 56px;
		}
	}
}
